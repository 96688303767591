import axios from "axios"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { numberFormat } from "../Common/utils"
import "./SimilarSoldProperties.scss"

const SimilarSoldProperties = ({ propertyInfo }) => {
  const [soldProperties, setSoldProperties] = useState(false)

  const token = process.env.GATSBY_STRAPI_FORM_TOKEN
  const strapi_url = process.env.GATSBY_STRAPI_SRC

  let data = JSON.stringify({
    area: propertyInfo.community
      ? propertyInfo.community
      : propertyInfo.area
      ? propertyInfo.area
      : "dubai",
  })

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${strapi_url}/api/avm/sold-properties`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },

    data: data,
  }

  useEffect(() => {
    axios
      .request(config)
      .then(response => {
        setSoldProperties(response.data)
        setTimeout(() => {
          if (
            typeof window !== "undefined" &&
            window.location.href.includes("mortgage-calculator")
          ) {
            const getId = document.getElementById("mortgage-calculator")
            getId?.scrollIntoView({ behavior: "smooth" })
          }
        }, 100)

        //   console.log(JSON.stringify(response.data))
      })
      .catch(error => {
        // console.log(error)
      })
  }, [propertyInfo.area])

  const { isMobile } = useDeviceMedia()

  return (
    <div className="similar-sold-wrapper">
      {soldProperties?.length > 0 && (
        <>
          <div className="property-horizontal-line"></div>
          <div className="sold-properties-content">
            <h5>Similar Sold Properties</h5>
            <div className="sold-container">
              {soldProperties?.length > 0 &&
                soldProperties.map((data, index) => {
                  return (
                    <div className="sold-list" key={index}>
                      <div className="address-and-date">
                        <p className="address">{data.sub_loc_2}</p>
                        <p className="sold-date">
                          Sold at{" "}
                          {format(new Date(data.evidence_date), "MMM MM, yyyy")}
                        </p>
                      </div>
                      <div className="counts">
                        <div className="square-meter">
                          <span>
                            {!isMobile ? "Unit" : ""} {data.unit_bua_sqm}
                          </span>
                        </div>
                        <div className="price">
                          <span>
                            AED {numberFormat(data.total_sales_price)}
                          </span>
                        </div>
                        <div className="bedroom">
                          <i className="icon bed-icon" />
                          <span>{data.no_beds}</span>
                        </div>
                        <div className="area-size">
                          <i className="icon area-icon" />
                          <span>{data.unit_bua_sqft} sq ft</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>

            <p className="powered-by">Powered by Property Monitor</p>
          </div>
        </>
      )}
    </div>
  )
}

export default SimilarSoldProperties
